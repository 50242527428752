@import '../../scss/variables';
@import "~react-rangeslider/lib/index.css";

$background: $gray;
$fillColor: $blue;
$labelColor: $theme_color;

.range-slider {
  > div {
    display: flex;
  }

  .range-slider-label {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 22px;
    color: $labelColor;
  }

  .Input {
    width: 20%;
    margin-right: 20px;

    input {
      padding: 15px 0;
      text-align: center;
    }
  }

  .rangeslider {
    width: 100%;

    height: 4px;
    border-radius: 20px;
    background: $background;
    box-shadow: none;

    .rangeslider__fill {
      background: $fillColor;
      box-shadow: none;
    }

    .rangeslider__handle {
      box-sizing: border-box;
      box-shadow: none;

      width: 18px;
      height: 18px;

      background: $fillColor;
      outline: none;
      border: none;

      &:after {
        display: none;
      }
    }
  }
}

@import '../../scss/_variables.scss';

$text: $theme_color;
$textSecond: $theme_color_secondary;

.flex-bar {
  width: 10%;
  min-width: 150px;
}

.nav-bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  min-width: 150px;
  width: 10%;
  max-width: 10%;

  padding-top: 30px;

  background: $nav_background;
  box-shadow: 6px 0 10px rgba(0, 0, 0, 0.09);

  z-index: 1;

  .menu-list {
    padding: 20px 0;

    .list-title {
      font-size: 22px;
      color: $text;
      cursor: pointer;
    }

    li + li,
    ul + li {
      margin-top: 20px;
    }

    .submenu-list {
      margin-top: 10px;

      li + li {
        margin-top: 10px;
      }

      padding-left: 10px;
    }
  }

  .menu-link {
    font-size: 18px;
    color: $text;

    &.menu-link-active {
      color: $textSecond;
    }
  }

  .nav-bar__link-container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    background: inherit;

    padding-left: 10%;

    z-index: 2; // z-index выше чем у лодера на таблице
  }

  .nav-bar__logo {
    display: flex;
    justify-content: center;

    margin-bottom: 40px;

    div {
      display: flex;
      justify-content: center;
    }

    div,
    svg {
      width: 90%;
    }
  }

  .nav-bar__link {
    font-size: 1.6em;

    margin-bottom: 25px;

    color: $text_primary;

    transition-property: color, opacity;
    transition-duration: 400ms;

    &:not(.nav-bar__button):hover {
      opacity: 0.6;
    }
  }

  .nav-bar__link_active {
    color: $text_secondary;

    &:hover {
      opacity: 1 !important; // просто выше селектор тяжелее *50
    }
  }

  .nav-bar__user-info {
    color: $theme_color;
    padding-right: 10px;

    .user-info__name {
      font-size: 14px;
    }

    .user-info__descr {
      margin: 20px 0;
      font-size: 12px;
    }
  }

  .nav-bar__button {
    font-size: 1.4em;

    color: $text_primary;

    &:hover {
      color: $text_secondary;
    }
  }

  .nav-bar__burger {
    display: none;
    cursor: pointer;
  }
}
@media screen and (max-width: $screen_breakpoint) {
  .nav-bar {
    position: fixed;

    width: 100%;
    max-width: 100vw;
    height: $mobile_nav_bar_height;

    padding-top: 0;

    .nav-bar__burger {
      display: block;
    }

    .nav-bar__app-bar {
      padding: 20px $page_padding;

      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 100%;
    }

    .nav-bar__logo {
      margin-bottom: 0;
      width: 150px;

      div {
        width: 100%;
      }
    }

    .nav-bar__link-container {
      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      height: 0;

      padding-top: 20px;
      padding-left: $page_padding;

      overflow: hidden;

      transition: height 400ms;
    }

    .nav-bar__link-container_open {
      height: calc(100vh - #{$mobile_nav_bar_height});
    }
  }
}

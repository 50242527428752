@import '../../scss/variables';

.pagination {
  display: flex;
  align-items: center;

  margin-top: 40px;

  font-size: 1.3em;

  .pagination__link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    color: $theme_color_secondary;

    &:hover:not(.pagination__link_active):not(.pagination__link_disable) {
      background: $gray;
    }
  }

  .pagination__link_active {
    background: $theme_color_secondary;
    color: $white;
  }

  .pagination__link_disable {
    cursor: default;
  }
}

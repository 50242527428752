@import "../../scss/variables";

.dadata {
  position: relative;

  .suggestions {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    z-index: 1;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);

    .suggest {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;

      & + .suggest {
        border-top: 1px solid $border;
      }

      &:hover {
        background-color: $gray;
      }
    }
  }
}

@import '../../scss/variables';

$default_color: $theme_color;
$focused_color: $theme_color_secondary;

.Input {
  position: relative;
  display: flex;
  font-family: 'Roboto', sans-serif;

  .Input__TextField {
    width: 100%;
    padding: 15px 20px;

    font-size: 14px;

    border: 1px solid $gray;
    border-radius: 4px;

    color: $default_color;

    &::placeholder {
      color: $default_color;
    }

    &:focus {
      border-color: $focused_color;
      outline: none;
    }

    &[disabled] {
      background: $gray;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 15px;

    padding: 5px;

    font-size: 14px;
    color: $default_color;
    cursor: text;
    border-radius: 4px;

    transform: translateY(-50%);

    transition: all 300ms;
  }

  &.Input_active label,
  .Input__TextField:focus + label {
    top: 0 !important;
    left: 10px;

    font-size: 14px;

    background: $white;
    color: $focused_color;
  }

  &.Input_active label {
    color: $default_color;
  }

  textarea {
    resize: none;

    & + label {
      top: 20px;
    }
  }

  &.TextArea {
    label {
      top: 20px;
    }
  }
}

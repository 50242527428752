.page-container.bonuses {
  .school-filter,
  .city-filter {
    width: 100%;
    max-width: 300px;

    .select-option-list {
      white-space: normal;
    }
  }

  .city-filter {
    max-width: 480px;
  }
}

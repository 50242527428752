.header {
  display: flex;
  align-items: center;

  .header__button {
    margin-left: 30px;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;

    .header__button {
      margin: 30px 0 0;
    }
  }
}

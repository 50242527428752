@import '../../scss/variables';

.loader {
  position: relative;
  width: 100%;
  height: 100%;

  z-index: 1;

  > div,
  svg {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 100px;
    height: 100px;

    z-index: 2;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    background-color: $white;
    opacity: 0.5;
  }
}

@import './_variables.scss';

#app {
  position: relative;
  display: flex;

  min-height: 100vh;

  background: $app_background;

  @media screen and (max-width: $screen_breakpoint) {
    flex-direction: column;
  }
}

.page-container {
  padding: $wrapper_padding;

  width: calc(90% - #{$page_padding});

  @media screen and (max-width: $screen_breakpoint) {
    width: 100%;
    margin-left: 0;
    padding: $wrapper_padding;
    padding-top: $mobile_nav_bar_height + $page_padding;
  }
}

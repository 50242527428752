@import '../../scss/variables';

$default_color: $theme_color;
$focused_color: $theme_color_secondary;

.select {
  position: relative;

  display: flex;
  align-items: center;

  width: auto;
  min-width: 160px;
  min-height: 49px;

  border: 1px solid $gray;
  border-radius: 4px;

  font-family: Roboto;
  font-size: 14px;
  color: $theme_color;

  cursor: pointer;

  &-label {
    position: absolute;
    left: 10px;
    top: 0;

    padding: 5px;

    font-size: 14px;
    background: $white;
    color: $default_color;
    cursor: text;
    border-radius: 4px;

    transform: translateY(-50%);

    transition: all 300ms;
  }

  .select-title,
  .select-title input {
    width: 100%;
    height: 100%;
  }

  .select-title {
    display: flex;
    align-items: center;

    padding: 0 40px 0 20px;

    input {
      display: flex;
      padding: 15px 20px 15px 0;
      font-size: inherit;
      color: $theme_color;

      outline: none;
      border: none;
      background: none;
    }
  }

  .select-option {
    padding: 15px 20px;

    &:hover {
      background: $gray;
    }
  }

  .select-option-active {
    background: #dadada;
  }

  .select-option-list {
    white-space: nowrap;

    position: absolute;
    top: calc(100% + 5px);
    left: -1px;

    width: 100%;
    height: 0;

    overflow: hidden;

    background-color: $white;

    border: 1px solid $gray;
    border-radius: 4px;
    border-width: 0;

    transition: all 300ms;

    z-index: 10;
  }

  &:after {
    content: '';

    position: absolute;
    top: calc(50% + 2px);
    right: 15px;
    transform: translateY(-50%);

    width: 15px;
    height: 10px;

    background: url('./select.svg') no-repeat;
    transition: all 250ms;
  }

  &.variant-second {
    width: auto;
    padding: 0;

    font-size: 17px;

    border: none;

    .select-title {
      padding-right: 30px;

      input {
        padding-right: 0;
      }
    }

    &:after {
      right: 0;
    }
  }

  &.select-open,
  &-always-open {
    border-color: $theme_color_secondary;

    .select-option-list {
      height: auto;
      max-height: 300px;

      overflow-y: scroll;
      border-width: 1px;
    }

    &:after {
      transform: rotate(180deg) translateY(50%);
    }
  }
}

@import '../../scss/variables';

.authStatusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 65px;

  margin: auto;

  color: $blue;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;


  & > img {
    width: 42px;

    margin-bottom: 24px;
  }
};
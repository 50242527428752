@import '../../scss/variables';

$theme: $theme_color;
$themeAdditional: $theme_color_secondary;
$border_color: rgb(241, 241, 241);

%wrapper {
  padding: 20px 20px;
}

.new-modal {
  position: fixed;
  left: 0;
  top: 0;

  display: none;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  z-index: 2;

  &.visible {
    display: flex;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 500px;
    max-height: calc(100% - 100px);

    background: white;
    border-radius: 5px;

    z-index: 1;

    > div {
      width: 100%;
    }
  }

  .modal-title,
  .modal-body,
  .modal-footer {
    @extend %wrapper;
  }

  .modal-cancel {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    background: transparent;
    border-radius: 50%;
    cursor: pointer;

    transition: background 300ms;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
    }

    svg {
      width: 12px;
      height: 12px;
    }

    svg {
      path {
        fill: $theme;
      }
    }

    &:hover {
      background: rgba(206, 204, 204, 0.5);
    }
  }

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 24px;
    text-align: left;
    border-bottom: 1px solid $border_color;
    color: $theme;
  }

  .modal-body {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    > *:not(:first-child) {
      margin-top: 20px;
    }
  }

  .modal-footer {
    border-top: 1px solid $border_color;
  }

  .modal-fade {
    position: absolute;

    width: 100%;
    height: 100%;

    background: black;
    opacity: 0.5;
  }
}

.new-modal {
  .modal-static {
    font-size: 16px;
    color: $theme;

    a {
      font-size: 16px;
      text-decoration: none;
      color: $theme;

      &:hover {
        color: $themeAdditional;
      }
    }
  }

  .files {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    a {
      margin-top: 10px;
    }
  }

  .photo-preview {
    width: 160px;
    height: 160px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .modal-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:not(:first-child) {
      margin-left: 10px;
    }

    .static {
      font-size: 17px;
      line-height: 22px;
      color: black;
    }

    &.flex-start {
      justify-content: flex-start;

      > div:not(:first-child) {
        margin-left: 30px;
      }
    }

    @media screen and (max-width: 525px) {
      flex-direction: column;

      div:not(:first-child) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  .modal-single {
    display: flex;

    > div {
      width: calc((100% / 3) - 10px);
    }
  }

  .modal-gender {
    display: flex;
    align-items: center;
    color: $theme;

    span {
      font-size: 16px;
    }
  }

  &.modal-confirmation{
    z-index: 1400;
    .modal-title{
      justify-content: flex-end;
    }
    .message-block{
      margin-bottom: 30px;
      font-size: 14px;
      & > div{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
}

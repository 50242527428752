@import '../../scss/variables';

$labelColor: black;

.checkbox {
  display: flex;
  align-items: center;

  .checkbox__input {
    position: relative;
    visibility: hidden;

    width: 20px;
    height: 20px;

    cursor: pointer;

    &:after {
      content: '';
      visibility: visible;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border-radius: 3px;

      transition: box-shadow 300ms;

      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }

    &:hover {
      &:before {
        opacity: 0.2;
      }
    }

    &:before {
      content: '';
      visibility: visible;
      opacity: 0;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: url('../../image/checked.svg') no-repeat;
      background-position: center center; // магические числа
      background-size: 12px; // магические числа

      transition: opacity 300ms;
    }

    &:checked {
      &:before {
        opacity: 1;
      }
    }
  }

  .checkbox__label {
    padding-top: 1px;
    padding-left: 20px;

    font-size: 1.5em;
    color: $labelColor;

    cursor: pointer;
  }
}

@import '../variables';

.promocode-group-page {
  .page-header {
    display: flex;
    flex-direction: column;

    .back {
      display: flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      transition: opacity 250ms;

      .back-svg {
        margin-right: 10px;
        transform: rotate(90deg);

        width: 12px;

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: $theme_color_secondary;
          }
        }
      }

      a {
        font-size: 16px;
        color: $theme_color_secondary;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .page-actions {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;

      &:first-child {
        > div + div {
          margin-left: 20px;
        }
      }
    }
  }
}

.promocode-group-modal {
  .modal-footer {
    .button {
      width: 100%;
    }
  }
}



@import '../../scss/variables';

.modal {
  width: 500px;

  .modal__close-btn {
    position: absolute;
    top: 14px;
    right: 8px;
  }

  .modal__title h2 {
    font-size: 2.4em;
    font-weight: 400;
    color: $theme_color;
  }

  .modal__content {
    padding-top: 1px; // фикс перекрывания оутлайна инпута

    #modal {
      > * {
        margin-top: 20px;
      }
    }
  }

  .modal__footer {
    display: flex;
    justify-content: center;

    padding: 0 24px 20px;
    margin: 0;
  }

  .modal__btn {
    margin: 0;
  }

  .modal__photo-preview {
    width: 160px;
    height: 160px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .modal-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
    }

    .modal__info {
      margin-bottom: 0;
    }

    .modal__info + .modal__info {
      margin-left: 20px;
    }
  }

  .modal__info {
    display: flex;

    font-size: 1.4em;
    margin-bottom: 20px;

    .info__title {
      color: $theme_color;
    }

    .info__value {
      margin-left: 10px;
    }
  }
}

@import '../../scss/variables';

$default_color: $theme_color;
$focused_color: $theme_color_secondary;

.input {
  position: relative;

  font-family: 'Roboto', sans-serif;

  textarea,
  input {
    width: 100%;
    height: 100%;
    padding: 15px 20px;

    font-size: 1.4em;

    border: 1px solid $gray;
    border-radius: 4px;

    color: rgb(145, 143, 143);

    &::placeholder {
      color: $default_color;
    }

    &:focus {
      border-color: $focused_color;
      outline: none;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 15px;

    padding: 5px;

    font-size: 1.4em;
    color: $default_color;
    cursor: text;

    transform: translateY(-50%);

    transition: all 300ms;
  }

  &.input_active label,
  input:focus + label {
    top: 0 !important;
    left: 10px;

    font-size: 1.2em;

    background: $white;
    color: $focused_color;
  }

  &.input_active label {
    color: $default_color;
  }

  textarea {
    resize: none;
  }

  &.textarea {
    label {
      top: 20px;
    }
  }
}

@import '../../scss/_variables';

$cell_padding: 15px 30px;
$table_min_width: 800px;

.table-container {
  margin-top: 40px;
  overflow-x: scroll;
}

.table {
  width: 100%;
  min-width: $table_min_width;

  font-size: 1.4em;

  border: 1px solid $gray;

  .thead {
    .tr {
      width: 100%;

      font-weight: 500;
      color: $theme_color_secondary;
    }
    .th {
      padding: $cell_padding;
      text-align: left;
    }
  }

  .tbody {
    .tr:nth-of-type(odd) {
      background: $gray;
    }

    .td {
      padding: $cell_padding;
      text-align: left;

      color: $theme_color;

      vertical-align: middle;
    }

    .table__image {
      width: 50px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;

        border-radius: 4px;
      }
    }
  }

  &.table-nowrap {
    td,th {
      white-space: nowrap;
    }
  }

  .table__nowrap {
    white-space: nowrap;
  }
}

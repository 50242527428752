@import '../../scss/variables';

.Actions {
  display: flex;
  justify-content: flex-end;

  .Actions-item {
    font-size: 20px;
    font-weight: bold;

    margin-left: 5px;

    opacity: 0.5;
    color: #333;
    cursor: pointer;

    &.active {
      opacity: 1;

      svg path {
        fill: $theme_color_secondary;
      }
    }

    &:not(.disabled) {
      .Actions-item:hover {
        opacity: 1;

        svg path {
          fill: $theme_color_secondary;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

@import './reset.scss';
@import './authorization.scss';
@import './app.scss';
@import './components.scss';

// pages

@import './pages/index';
@import './pages/settingsPage';
@import './pages/promocodesGroupsPage';
@import './pages/promocodeGroupPage';

html {
  -ms-overflow-style: none;
}

.scroll-brake {
  overflow: hidden;
}

::-webkit-input-placeholder {
  color: $theme_color;
}
::-moz-placeholder {
  color: $theme_color;
}
:-ms-input-placeholder {
  color: $theme_color;
}
:-moz-placeholder {
  color: $theme_color;
}

@import '../variables';

.page-stats {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  > div {
    width: auto;
    margin-top: 10px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.page-actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  > * {
    margin-top: 20px;
    margin-right: 20px;
  }
}

.link {
  font-size: 16px;
  color: $theme_color_secondary;
  opacity: 1;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.7;
  }
}

@import '../variables';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 15px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  border-radius: 8px;

  .card__value {
    font-size: 2.7em;
    font-weight: bold;
    color: $theme_color_secondary;
  }

  .card__name {
    text-align: center;

    margin-top: 5px;
    font-size: 1.4em;
    color: $theme_color;
  }
}

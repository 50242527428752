@import '../../scss/variables';
@import "~daterangepicker/daterangepicker.css";

.rangedatepicker-container {
  position: relative;

  .rangedatepicker-input {
    min-width: 200px;
    height: 50px;

    padding: 15px 20px;

    font-size: 14px;
    line-height: 1.5;
    color: $theme_color;

    border-radius: 4px;
    border: 1px solid $gray;

    &:focus {
      outline: none;
      border-color: $theme_color_secondary;
    }

    &[disabled] {
      background: $gray;
    }
  }
}

.daterangepicker .ranges li.active {
  background-color: $theme_color_secondary;
}


.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $theme_color_secondary;
}

.daterangepicker .drp-buttons .btn-primary {
  color: #fff;
  background-color: $theme_color_secondary;
  border: none;
}

.daterangepicker {
  //display: flex;

  .btn {
    border-radius: 4px;
    cursor: pointer;
  }

  .cancelBtn {
    background: $white;
  }
}

@import '../../scss/variables';

.edit-field {
  display: flex;
  flex-direction: column;

  &-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    label {
      font-size: 14px;
      color: $theme_color;
      cursor: text;
      border-radius: 4px;
    }
  }

  &-wrap-button {
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
  }

  &-input {
    font-family: 'Roboto', sans-serif;

    width: 100%;
    min-height: 100px;
    padding: 15px 20px;

    font-size: 14px;

    outline: none;
    border: 1px solid $gray;
    border-radius: 4px;

    color: $theme_color;

    resize: vertical;
  }
}

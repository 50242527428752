@import './_variables.scss';
@import './components/header.scss';
@import './components/control.scss';
@import './components/card.scss';
@import './components/datepicker.scss';

#app, .modal {
  .btn-reset {
    border: none;
    background: none;

    text-align: left;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  %btn {
    width: 175px;
    padding: 15px 0;

    font: 1.4em 'Roboto', sans-serif;
    font-weight: 500;

    border: none;
    border-radius: $btn_border_r;
    cursor: pointer;

    transition: box-shadow 300ms;

    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .btn {
    @extend %btn;
  }

  .btn-primary {
    @extend %btn;

    background: $btn_primary_bg;
    color: $btn_primary_color;
  }

  .btn_size_s {
    padding: 15px 25px;
  }

  .btn_size_l {
    width: 100%;
    padding: 15px;
  }

  .btn-link {
    font-size: 1.1em;
    color: $theme_color_secondary;

    background: none;

    opacity: 0.5;

    transition: opacity 400ms;

    &:hover {
      opacity: 1;
    }

    &[disabled] {
      color: $theme_color;
    }
  }

  .btn-secondary {
    @extend %btn;

    background: $btn_secondary_bg;
    color: $btn_secondary_color;
  }

  %h {
    display: inline-block;
    color: $text_primary;
  }

  .h2 {
    @extend %h;

    font-weight: normal;
    font-size: 3em;
  }

  %input {
    padding: 15px 20px;
    font: 1.4em 'Roboto', sans-serif;

    border: 1px solid $gray;
    border-radius: 4px;

    &::placeholder {
      color: $theme_color;
    }

    &:focus {
      outline: none;
      border: 1px solid $theme_color_secondary;
    }
  }

  .input-group {
    display: flex;

    .input:not(:first-child) {
      margin-left: 10px;
    }

    @media screen and (max-width: 525px) {
      // 525 - магическое число
      flex-direction: column;

      .input:not(:first-child) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  .form-control {
    margin-top: 20px;
  }

  .sales__select {
    max-width: 250px;
  }

}

@import '../variables';

.promocodes-groups-page {
  .confirm-status {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    font-size: 12px;

    &:hover,
    &.active {
      color: $theme_color_secondary;
    }
  }
}

.promocodes-groups-modal {
  .modal-footer {
    display: flex;
    justify-content: flex-end;

    .button + .button {
      margin-left: 20px;
    }
  }
}

@import '../../scss/variables';

.pagination {
  display: flex;
  margin: 20px 0;

  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      height: 25px;
      font-size: 16px;
      background: transparent;
      color: $theme_color;
      cursor: pointer;
      outline: none;
    }

    &.next,
    &.previous {
      a {
        border-radius: 8px;
        width: auto;
      }
    }

    &.previous {
      margin-right: 10px;
    }

    &.next {
      margin-left: 10px;
    }

    &.active {
      a {
        background: $theme_color_secondary;
        color: $white;
      }
    }
  }
}

@import '../../scss/variables';

.search-btn {
  display: flex;
  max-height: 49px;

  .input {
    width: 100%;
  }

  .search-btn__input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    outline: none;

    &:focus {
      border: 1px solid $theme_color_secondary;
      border-right: none;
    }
  }

  .search-btn__btn {
    width: 100%;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

@import '../../scss/variables';

.settings-page {
  padding-bottom: 60px;

  .page-row {
    display: flex;
    flex-wrap: wrap;

    & + .page-row {
      margin-top: 40px;
    }
  }

  .page-block {
    display: flex;

    flex-direction: column;

    width: 300px;
    height: 100%;
    min-width: 300px;
    margin-top: 20px;
    margin-right: 80px;

    .link {
      margin-top: 40px;
    }

    .h2 {
      margin-bottom: 40px;
    }

    > div {
      &:nth-child(2) {
        margin-top: auto;
      }

      & + div {
        margin-top: 20px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .subject-status {
    color: $theme_color_secondary;
    font-size: 12px;
  }

  .types {
    width: 500px;

    .select {
      &-title {
        .Actions {
          display: none;
        }
      }

      &-option-list {
        white-space: normal;
      }

      &-option-subject {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .empty-types {
    width: 400px;
    height: 300px;

    &-list {
      margin-top: 0 !important;
    }

    .h2 {
      font-size: 16px!important;
      margin-bottom: 10px;
    }

    .select {
      &-option-list {
        height: 235px;
        margin-bottom: 40px;
        z-index: 1;
      }
    }
  }

  .partner-types {
    width: 500px;
    height: 400px;

    > div {
      margin-top: 0 !important;
    }

    .select-option-list {
      z-index: 0;
    }
  }
}

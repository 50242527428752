@import '../../scss/variables';

$borderVariantColor: $blue;
$neutralBackground: $theme_color;
$cancelBackground: red;
$background: $blue_sky;
$color: $white;

.button {
  position: relative;
  display: flex;

  button {
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    width: 100%;

    padding: 0 26px;

    font-weight: 500;
    font-size: 17px;
    color: $color;

    border: none;
    border-radius: 4px;

    outline: none;
    cursor: pointer;
    background: $background;
  }

  &.variant-neutral {
    button {
      background: $neutralBackground;
    }
  }

  &.variant-cancel {
    button {
      background: $cancelBackground;
    }
  }

  &.variant-border {
    button {
      background: transparent;
      border: 1px solid $borderVariantColor;
      color: $borderVariantColor;
    }
  }

  &.loading {
    &:after {
      content: '';

      position: absolute;
      left: 0;
      top: 0;

      display: flex;

      width: 100%;
      height: 100%;

      background: rgba($white, .8) url('../../image/loader.svg') no-repeat center center;
      background-size: 35px 35px;
    }
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  .login-form {
    width: 300px;

    .Input + .Input {
      margin-top: 20px;
    }

    .button {
      margin-top: 20px;
    }
  }
}

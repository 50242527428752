@import '../../scss/variables';

$borderRadius: 8px;

.meta-stat {
  padding: 10px 15px 15px;
  text-align: center;

  box-shadow: $boxShadow;
  border-radius: $borderRadius;

  .stat-value {
    font-weight: bold;
    font-size: 30px;
    color: $blue;
  }

  .stat-label {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: $theme_color;
  }
}

@import '../variables';

.control-block {
  margin-top: 40px;
  .control-block__line {
    display: flex;

    &.flex-wrap {
      flex-wrap: wrap;
    }

    &.mobile_column {
      @media screen and (max-width: $screen_breakpoint) {
        flex-direction: column;
      }
    }

    & + .control-block__line {
      margin-top: 40px;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.bonuses-selects {
      > * + .old-select,
      .old-select + .old-select {
        margin-left: 20px;
      }
    }
  }

  .control-block__line_just-cont_end {
    justify-content: flex-end;
  }

  .control-block__card {
    margin-left: 25px;

    @media (max-width: 820px) {
      margin-left: 10px;
    }

    @media screen and(max-width: $screen_breakpoint) {
      margin-top: 25px;
      margin-left: 0;
    }
  }

  .control-block__box {
    display: flex;
    justify-content: space-between;
  }

  .control-block__search {
    .old-select {
      min-width: 150px;
      border: none;
    }
  }

  .control-block__datepicker {
    margin-left: 20px;

    @media (max-width: $screen_breakpoint) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
